<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>Thống kê tài khoản</b-card-title>

    </b-card-header>
    <b-card-body class="pb-2">

      <b-row>
        <b-col cols="6">
          <select-date
            v-model="startDate"
            label="Ngày bắt đầu"
          />
        </b-col>
        <b-col cols="6">
          <select-date
            v-model="endDate"
            label="Ngày kết thúc"
          />
        </b-col>
        <b-col
          v-if="userRole.name !== 'department_of_health' && userRole.name !== 'medical_facility'"
          :md="resolveColForFilter"
        >
          <select-province
            v-model="province"
            :label="null"
          />
        </b-col>
        <b-col
          v-if="userRole.name !== 'medical_facility'"
          cols="4"
          :md="resolveColForFilter"
        >
          <select-district
            v-model="district"
            :province="province"
            :label="null"
          />
        </b-col>
        <b-col
          cols="4"
          v-if="userRole.name !== 'medical_facility'"
          :md="resolveColForFilter"
        >
          <select-ward
            v-model="ward"
            :district="district"
            :label="null"
          />
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="12">
          <vue-good-table
            mode="remote"
            :total-rows="data.length"
            :columns="columns"
            :rows="data"
          >
            <template slot="loadingContent">
              <b-spinner
                label="Loading"
                type="grow"
              />
            </template>
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Code -->
              <span v-if="props.column.field === 'index'">
                {{ props.row._id ? props.row.originalIndex + 1 : '' }}
              </span>
              <span
                v-else-if="props.column.field === 'totalCountMember'"
                :class="props.row._id ? '' : 'font-weight-bolder'"
              >
                {{ Number(props.row.totalCountMember + props.row.totalCountCustomer).toLocaleString() }}
              </span>
              <span
                v-else-if="props.column.field === 'totalCountCustomer'"
                :class="props.row._id ? '' : 'font-weight-bolder'"
              >
                {{ Number(props.row.totalCountCustomer).toLocaleString() }}
              </span>

              <!-- Column: Common -->
              <span
                v-else
                :class="props.row._id ? '' : 'font-weight-bolder'"
              >
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <div slot="emptystate">
              <div class="text-center text-muted">
                Không có bản ghi nào!
              </div>
            </div>
          </vue-good-table>
        </b-col>
      </b-row>

    </b-card-body>

  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCol, BRow, BSpinner,
} from 'bootstrap-vue';

// eslint-disable-next-line import/no-cycle
import { ref } from '@vue/composition-api';
import useJwt from '@/auth/jwt/useJwt';
import { VueGoodTable } from 'vue-good-table';
import SelectDate from '@/views/components/SelectDate.vue';
import SelectDistrict from '@/views/components/SelectDistrict.vue';
import SelectProvince from '@/views/components/SelectProvince.vue';
import SelectWard from "@/views/components/SelectWard.vue";
import moment from 'moment';
import { getUserRole, getUserProvince, getUserDistrict } from '@/auth/utils';

export default {
  components: {

    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCol,
    BRow,
    BSpinner,
    VueGoodTable,
    SelectDate,
    SelectWard,
    SelectDistrict,
    SelectProvince,
  },
  data() {
    return {
      isLoading: false,
      province: null,
      district: ref(getUserDistrict()),
      ward: null,
      startDate: null,
      endDate: null,
      userRole: null,
      resolveColForFilter: null,
      data: [],
      columns: [
        {
          label: '#',
          field: 'index',
          width: '40px',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: false,
          orderable: false,
        },
        {
          label: 'Đơn vị',
          field: 'name',
          width: '200px',
          sortable: false,
          orderable: false,
        },
        {
          label: 'Tổng số tài khoản',
          field: 'totalCountCustomer',
          width: '40px',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: false,
          orderable: false,
        },
        {
          label: 'Tổng số người dân',
          field: 'totalCountMember',
          width: '40px',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: false,
          orderable: false,
        },
      ],
    };
  },

  watch: {
    province() {
      this.loadData();
    },
    district() {
      this.loadData();
    },
    ward() {
      this.loadData();
    },
    startDate() {
      this.loadData();
    },
    endDate() {
      this.loadData();
    },
  },

  created() {
    this.province = getUserProvince()
    this.district = getUserDistrict()
    this.userRole = getUserRole()
    if(this.userRole.name === 'department_of_health') {
      this.resolveColForFilter = 6
    } else {
      this.resolveColForFilter = 4
    }
    this.loadData();
  },

  methods: {
    loadData() {
      this.isLoading = true;

      const dataObject = {};
      if (this.startDate && moment(this.startDate, "DD-MM-YYYY", true).isValid()) {
        dataObject.startTime = moment(this.startDate, "DD-MM-YYYY", true).startOf('day');
      }
      if (this.endDate && moment(this.endDate, "DD-MM-YYYY", true).isValid()) {
        dataObject.endTime = moment(this.endDate, "DD-MM-YYYY", true).endOf('day');
      }

      if (this.province) {
        dataObject.province_id = this.province._id
      }

      if (this.district) {
        dataObject.district_id = this.district._id
      }

      if (this.ward) {
        dataObject.ward_id = this.ward._id
      }

      useJwt.getDashboardUser(dataObject).then(response => {
        const temp = response.data;
        this.data = [...temp, {
          _id: null,
          code: '00',
          name: 'TỔNG CỘNG',
          totalCountCustomer: temp.reduce(
            (partialSum, a) => +partialSum + +a.totalCountCustomer,
            0,
          ) || 0,
          totalCountMember: temp.reduce(
            (partialSum, a) => +partialSum + +a.totalCountMember,
            0,
          ) || 0,
        }];
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

</style>
